<template>
    <markets-ui-container
        :class="namespace()"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showLoading="showLoading"
        :showPrint="watchedSettings.showPrint"
        @print-click="quickPrint"
    >
        <div
            slot="content"
            :class="[
                namespace('content'),
                errorMessage ? namespace('error') : ''
            ]"
        >
            <navigation
                v-if="watchedSettings.showNav && !errorMessage"
                :skin="watchedSettings.skin"
                :dataModel="modules"
                :labels="initedLabels"
                @change="changeActiveModule"
            >
            </navigation>
            <section
                v-if="!errorMessage"
                :class="wrapperClass"
                ref="salModuleWrapper"
            ></section>
        </div>
    </markets-ui-container>
</template>
<script>
import $ from 'jquery';
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import navigation from './navigation';
import { MODULES } from './metadata/modules';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-report',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: {
        symbol: {
            type: String
        }
    },
    components: { navigation },
    computed: {
        wrapperClass() {
            const cls = [this.namespace('wrapper')];
            if (!this.watchedSettings.showNav) {
                cls.push(this.namespace('wrapper__no-nav'));
            }
            if (this.watchedSettings.layout !== 'list') {
                cls.push(this.namespace('wrapper__no-title'));
            }
            return cls;
        },
        modules() {
            const types = this.getSALTypes();
            const TYPE_MODULES = MODULES[types.reportType];
            let modules = this.watchedSettings.modules[types.reportType];
            if (types.securityType === 'FE') {
                modules = modules.filter(id => id !== 'people');
            }
            modules = modules.filter(
                id =>
                    !TYPE_MODULES[id].licenses ||
                    utils.inArray(
                        TYPE_MODULES[id].licenses,
                        this.watchedSettings.type
                    ) > -1
            );
            const { bundle } = this.getSALPaths({
                bundle: this.watchedSettings.salBundleHost,
                service: this.watchedSettings.salServiceHost
            });
            modules = modules.map(id => {
                return {
                    id,
                    mwcId: `${TYPE_MODULES[id].name}-${this.guid}`,
                    name: TYPE_MODULES[id].name,
                    label: TYPE_MODULES[id].labelKey
                        ? this.initedLabels[TYPE_MODULES[id].labelKey]
                        : this.initedLabels[id],
                    deps: [this.getFullScriptSrc(bundle, 'sal-components')],
                    active: id === this.watchedSettings.activeModule
                };
            });
            if (!utils.find(modules, m => m.active)) {
                modules[0].active = true;
            }
            return modules;
        },
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    showHeader: false,
                    showPrint: false,
                    showBorder: false,
                    showNav: true, // false means individual component mode
                    languageId: 'en-US',
                    skin: '',
                    type: 'basic', // basic, premium, free
                    layout: 'tab',
                    salBundleHost: null,
                    salServiceHost: null,
                    oauth2Token: null,
                    realTimeToken: null,
                    modules: {
                        equity: [
                            'quote',
                            'analysis',
                            'priceFairvalue',
                            'trailingReturns',
                            'financials',
                            'valuation',
                            'performance',
                            'dividends',
                            'ownership',
                            'executive',
                            'profile'
                        ],
                        managedInvestment: [
                            'quote',
                            'analysis',
                            'performance',
                            'risk',
                            'price',
                            'portfolio',
                            'people',
                            'parent'
                        ],
                        index: [
                            'quote',
                            'description',
                            'performance',
                            'risk',
                            'portfolio'
                        ]
                    },
                    activeModule: 'quote',
                    quote: {
                        newsServiceProdCode: 'QS',
                        // newsServiceVendors: {},
                        // newsServiceVendors: {
                        //     headlines: 'DowJones,Benzinga', //Default is empty and to show default headlines data. You can set headlines vendor to display headlines data from specified vendors. A comma-separated vendors to customize multiple headlines vendors.
                        //     pressReleases: 'PRNewswire,Businesswire', //Default is empty and to show default pressReleases data. You can set pressReleases vendor to display pressReleases data from specified vendors. A comma-separated vendors to customize multiple pressReleases vendors.
                        //     all: 'DowJones,PRNewswire' //Default is empty and to show news data from all vendors which your product owns. You can set it to display news data from specified vendors. A comma-separated vendors to customize multiple vendors.
                        // },
                        hideNews: false,
                        showMqr: false, // Enables display of the Morningstar Quant Rating if available
                        disableDefaultReader: false,
                        hideArchiveBackIcon: false,
                        hideChiclet: true,
                        hideQuoteHeader: false,
                        hideKeyStats: false,
                        showEodChart: false,
                        quoteActiveTabId: 'quote',
                        fullKeyRatiosUrl: ''
                        // fullChartConfig: {
                        //     intervalType: 'OneDay',
                        //     frequency: '1',
                        //     sessionKey: '', // Optional. For Markets team to change the session key of full chart.
                        //     env: '', // Optional. For Markets team to change the environment of full chart.
                        //     instId: '' // Optional. For Markets team to get the configurations of full chart.
                        // }
                    },
                    trailingReturns: {
                        // chartConfig: {
                        //     colors: ['#1F55A5', '#AC0033', '#DCAA00'],
                        //     ticksNumber: 2,
                        //     tooltip: false,
                        //     legend: false,
                        //     showGrayBands: true,
                        //     barPadding: 3,
                        //     barGroupFixedPadding: 0,
                        //     y1TickWidth: 70,
                        //     y1TickSymbol: '',
                        //     yPaddingSmall: 13,
                        //     marginBottom: 1,
                        //     yDomainForceNegative: true
                        // }
                    },
                    analysis: {
                        // hideArchiveLink: true
                    },
                    financials: {
                        hideChiclet: true
                    },
                    valuation: {
                        isExpanded: true //Determines whether or not the component shows as expanded or collapsed by default. Defaults to false.
                    },
                    performance: {
                        isExpanded: true //Determines whether or not the component shows as expanded or collapsed by default. Defaults to false.
                    },
                    ownership: {
                        hideChiclet: true,
                        isExpanded: true //Determines whether or not the component shows as expanded or collapsed by default. Defaults to false.
                    },
                    executive: {
                        hideChiclet: true,
                        isExpanded: true //Determines whether or not the component shows as expanded or collapsed by default. Defaults to false.
                    },
                    price: {
                        hideChiclet: true
                    },
                    portfolio: {
                        isShowBasicContent: true,
                        isExpanded: true
                    },
                    parent: {
                        hideChiclet: true,
                        isBasic: true
                    },
                    pdfPrintConfig: {
                        hasPdfPageBreaks: true,
                        pdfHeaderText: ' ',
                        /* eslint-disable */
                        pdfFooterText: `© Morningstar ${new Date().getFullYear()}. All Rights Reserved. Unless otherwise provided in a separate agreement, you may use this report only in the country in which its original distributor is based. The information, data, analyses and opinions presented herein do not constitute investment advice; are provided solely for informational purposes and therefore are not an offer to buy or sell a security; and are not warranted to be correct, complete or accurate. The opinions expressed are as of the date written and are subject to change without notice. Except as otherwise required by law, Morningstar shall not be responsible for any trading decisions, damages or other losses resulting from, or related to, the information, data, analyses or opinions or their use. The information contained herein is the proprietary property of Morningstar and may not be reproduced, in whole or in part, or used in any manner, without the prior written consent of Morningstar. Investment research is produced and issued by subsidiaries of Morningstar, Inc. including, but not limited to, Morningstar Research Services LLC, registered with and governed by the U.S. Securities and Exchange Commission.`,
                        /* eslint-enable */
                        orientation: 'landscape',
                        pdfMargins: {
                            top: 38,
                            bottom: 81,
                            left: 43,
                            right: 19
                        },
                        pdfMarginStyle: `<style type="text/css">
                            .header{
                                height: 4px;
                                padding-bottom: 3px;
                                border-bottom: 1px solid #151515 !important;
                            }
                        </style>`,
                        pdfOverrideStyle: `<style type="text/css">
                            html,
                            body,
                            p,
                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6,
                            ul,
                            ol,
                            li {
                                margin: 0;
                                padding: 0;
                                border: 0;
                                font-size: 100%;
                                vertical-align: baseline;
                            }
                            html, body {
                                overflow: initial;
                                background-image: none;
                            }
                            .mwc-markets-report-wrapper{
                                height: auto;
                            }
                            .mwc-markets-report-sal-module {
                                width: 100%;
                                height: 100%;
                            }
                            .sal-components-sustainability-wrapper {
                                padding-top: 110px !important;
                            }
                            .sal-components-mip-carbon-metrics-wrapper {
                                page-break-after: always !important;
                                padding: 0px 10px 0 10px !important;
                            }
                            .sal-components-mip-holdings-wrapper {
                                padding: 0px 10px 0 10px !important;
                            }
                            .pillar-cards-title-area,.sal-component-header,.sal-components-holdings__header {
                                border-top: none !important;
                            }

                        </style>`
                    }
                },
                labels,
                intlNamespace: 'mwc-markets-report'
            },
            watchedSettings: {},
            showLoading: false,
            security: null
        };
    },
    watch: {
        symbol(value) {
            this.changeSymbol(value);
        },
        skin(value) {
            this.watchedSettings.skin = value;
        }
    },
    created() {
        this.SALApplication = [];
        this.guid = utils.guid();
        this.namespace = utils.namespace('report');
        Object.keys(this.settings).forEach(key => {
            this.$set(this.watchedSettings, key, this.settings[key]);
        });
        if (this.watchedSettings.layout === 'list') {
            // this.watchedSettings.activeModule = null;
            if (this.watchedSettings.autoHeight) {
                this.watchedSettings.showNav = false;
            }
        }
        if (!this.watchedSettings.showNav) {
            // individual mode, then no need to change module automaticlly
            this.autoChangeModule = false;
        } else {
            this.autoChangeModule = true;
        }

        this.moduleStatus = {};
        this.symbolQueue = [];
        this.showLoading = true;
        this.throttleResize = utils.throttle(entry => {
            this.resize(entry.contentRect.width);
        }, 200);
        this.changeActiveModule = utils.throttle((item, force) => {
            if (this.watchedSettings.activeModule !== item.id) {
                this.watchedSettings.activeModule = item.id;
                force = this.watchedSettings.layout !== 'list';
            }
            if (force) {
                this._changeActiveModule();
                this.changeSettings(this._getStoreSettings());
            } else {
                this._scrollActiveModule(item);
            }
        }, 200);
        this.containerEle = null; // need print position containers element.
        this.throttleScroll = utils.throttle(e => {
            if (this.$salModules && this.$bootstrapElement) {
                if (
                    this.$wrapper.scrollTop() +
                        this.$wrapper.outerHeight(true) ===
                    this.$bootstrapElement.outerHeight(true)
                ) {
                    this.watchedSettings.activeModule = this.modules[
                        this.modules.length - 1
                    ].id;
                } else {
                    const offsetTop = this.$wrapper.offset().top;
                    const modules = [];
                    this.$salModules.each((index, item) => {
                        const $ele = $(item);
                        if (
                            Math.floor($ele.offset().top) - 5 <=
                            Math.ceil(offsetTop)
                        ) {
                            modules.push($ele.attr('data-id'));
                        }
                    });
                    this.watchedSettings.activeModule =
                        modules[modules.length - 1];
                }
            }
        }, 500);
    },
    mounted() {
        utils.resizeObserver.observe(this.$el, this._resizeObserverCallback);
        this.$wrapper = $(this.$refs.salModuleWrapper);
        this.documentWidth = document.documentElement.clientWidth;
        if (
            !this.watchedSettings.realTimeToken ||
            !this.watchedSettings.oauth2Token
        ) {
            if (this.mktdata) {
                utils.tokenManager
                    .setHandler(() => {
                        return this.mktdata.queryPermission({
                            fields: 2048
                        });
                    })
                    .subscribe(this._tokenReadyCallback);
                if (
                    this.watchedSettings.layout === 'list' &&
                    !this.watchedSettings.autoHeight
                ) {
                    this.$wrapper.on('scroll', this.throttleScroll);
                }
            } else {
                this.showLoading = false;
            }
        } else {
            this.changeSymbol(this.watchedSettings.symbol);
        }
    },
    beforeDestroy() {
        utils.tokenManager.unsubscribe(this._tokenReadyCallback);
        utils.resizeObserver.unobserve(this.$el, this._resizeObserverCallback);
        this.$wrapper.off('scroll');
    },
    methods: {
        exposeMethods() {
            return ['quickPrint'];
        },
        _tokenReadyCallback(res) {
            if (res instanceof Error) {
                this._log('ERROR', 'Failed to retrive the tokens');
                this._setError(this.ERROR_TYPES.SERVICE);
            } else {
                this.watchedSettings.realTimeToken =
                    this.watchedSettings.realTimeToken || res.realtime;
                this.watchedSettings.oauth2Token =
                    this.watchedSettings.oauth2Token || res.oauth2;
                this.changeSymbol(this.watchedSettings.symbol);
            }
        },
        _resizeObserverCallback({ entry }) {
            this.throttleResize(entry);
        },
        resize(width) {
            if (width > 0 && this.SALApplication.length > 0) {
                const salModuleElements = [];
                if (this.watchedSettings.layout === 'list') {
                    this.modules.forEach(m => {
                        salModuleElements.push(
                            this.$wrapper.find(`[mwc-id='${m.mwcId}']`)
                        );
                    });
                } else {
                    salModuleElements.push(
                        this.$wrapper.find(
                            `[mwc-id='${this.activeModule.mwcId}']`
                        )
                    );
                }
                if (salModuleElements.length) {
                    salModuleElements.forEach(element => {
                        const bps = element.attr('data-eq-pts');
                        if (bps) {
                            const bpState = [];
                            bps.split(',').forEach(bp => {
                                bp = bp.split(':');
                                if (bp[1] <= width) {
                                    bpState.push(bp[0]);
                                }
                            });
                            element.attr('data-eq-state', bpState.join(''));
                        }
                    });
                    // when change element size to trigger report window resize method
                    let triggerEvent;
                    if (typeof Event === 'function') {
                        triggerEvent = new Event('resize');
                    } else {
                        // for IE11
                        triggerEvent = document.createEvent('Event');
                        triggerEvent.initEvent('resize', false, true);
                    }
                    window.dispatchEvent(triggerEvent);
                }
            }
        },
        getBundles(reportType, isPremium) {
            let name;
            switch (reportType) {
                case 'equity':
                    name = isPremium ? 'app' : 'eqsv-basic-page';
                    break;
                case 'index':
                    name = isPremium ? 'indexapp' : 'index-basic-page';
                    break;
                default:
                    name = isPremium ? 'mipapp' : 'mip-basic-page';
                    break;
            }
            return `sal-components-${name}`;
        },
        changeSymbol(symbol) {
            if (symbol) {
                this.watchedSettings.symbol = symbol;
                this.symbolQueue.push(symbol);
                this.acceptTicker(symbol);
                this.runSymbolQueue();
            }
        },
        runSymbolQueue() {
            const symbol = this.symbolQueue.shift();
            this.showLoading = true;
            this.mktdata
                .securities([symbol])
                .then(securities => {
                    this.error = {};
                    if (
                        this.security &&
                        this.security.securityId === securities[0].securityId
                    ) {
                        this.showLoading = false;
                        return;
                    }
                    if (securities[0].rtType === '2') {
                        this._setError(
                            this.ERROR_TYPES.INVALID_INVESTMENT_TYPE
                        );
                        return;
                    }
                    this.security = securities[0];
                    this.setMWCSALModulesConfig();
                    this.changeActiveModule(
                        {
                            id: this.watchedSettings.activeModule
                        },
                        true
                    );
                })
                .catch(e => {
                    this._setError(this.ERROR_TYPES.SERVICE);
                });
        },
        _changeActiveModule() {
            this.showLoading = true;
            this.initActiveSALModule();
        },
        getSALTypes() {
            let reportType, securityType;
            if (this.security) {
                securityType = this.security.mstarType;
                switch (securityType) {
                    case 'ST':
                        reportType = 'equity';
                        break;
                    case 'FE':
                    case 'FC':
                    case 'FO':
                        reportType = 'managedInvestment';
                        break;
                    case 'XI':
                        reportType = 'index';
                        break;
                    default:
                        reportType = 'managedInvestment';
                        break;
                }
            } else {
                reportType = 'equity';
                securityType = 'ST';
            }
            return {
                reportType,
                securityType
            };
        },
        setMWCSALModulesConfig() {
            const components = {};
            const securityType = this.security.mstarType;
            const needSecExchangeId =
                (securityType === 'ST' ||
                    securityType === 'FE' ||
                    securityType === 'FC') &&
                this.security.rtMarketDataExchangeID;
            // for stock/etf/cef, pass the market data exchange id
            const settings = needSecExchangeId
                ? {
                      languageId: this.watchedSettings.languageId,
                      secExchangeList: this.security.rtMarketDataExchangeID
                  }
                : {
                      languageId: this.watchedSettings.languageId
                  };
            this.modules.forEach(m => {
                components[m.mwcId] = {
                    type: m.name,
                    settings: utils.extend(settings, this.watchedSettings[m.id])
                };
            });
            if (window.mwc) {
                window.mwc.configuration.setAppConfig({
                    components
                });
            }
        },
        setSALModuleValues(SALApplication) {
            if (SALApplication) {
                const { service } = this.getSALPaths({
                    bundle: this.watchedSettings.salBundleHost,
                    service: this.watchedSettings.salServiceHost
                });
                const types = this.getSALTypes();
                const { realTimeToken, oauth2Token } = this.watchedSettings;
                SALApplication.value('REALTIME_TOKEN', realTimeToken)
                    .value(
                        'SALContentType',
                        `${
                            this.watchedSettings.type === 'premium'
                                ? 'nNsGdN3REOnPMlKDShOYjlk6VYiEVLSdpfpXAm7o2Tk='
                                : 'e7FDDltrTy+tA2HnLovvGL0LFMwT+KkEptGju5wXVTU='
                        }`
                    )
                    .value('ACCESS_TOKEN', oauth2Token)
                    .value(
                        'serviceBase',
                        `${service}/sal-service/v1/${
                            types.reportType === 'equity' ? 'stock/' : ''
                        }`
                    );
                if (this.security) {
                    SALApplication.value('securityIds', {
                        shareClassId:
                            types.reportType === 'equity'
                                ? this.security.performanceId
                                : this.security.securityId,
                        securityType: types.securityType
                    });
                }
            }
        },
        initActiveSALModule() {
            this.activeModule = utils.find(this.modules, m => m.active);
            if (!this.activeModule) {
                this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                return;
            } else if (
                !this.autoChangeModule &&
                this.activeModule.id !== this.watchedSettings.activeModule
            ) {
                const types = this.getSALTypes();
                if (
                    utils.findIndex(
                        this.watchedSettings.modules[types.reportType],
                        this.watchedSettings.activeModule
                    ) === -1
                ) {
                    this._setError(this.ERROR_TYPES.INVALID_INVESTMENT_TYPE);
                    return;
                }
            }
            this.loadActiveSALModule();
        },
        loadActiveSALModule() {
            const deps = this.getSALModuleDeps();
            if (this.isSALModuleDepsReady(deps)) {
                this.mountActiveSALModule(deps);
            } else {
                deps.forEach(src => {
                    utils.loadJSScript(
                        src,
                        () => {
                            this.mountActiveSALModule(deps);
                        },
                        () => {
                            this.mountActiveSALModule(deps);
                        }
                    );
                });
            }
        },
        isSALModuleDepsReady(deps) {
            return (
                deps.filter(src => !utils.isJSResponseReceive(src)).length === 0
            );
        },
        mountActiveSALModule(deps) {
            if (this.isSALModuleDepsReady(deps) && !this.symbolQueue.length) {
                this.$nextTick(() => {
                    this.$wrapper = $(this.$refs.salModuleWrapper).empty();
                    this.$bootstrapElement = $(
                        `<div class="${this.namespace('bootstrap')}"></div>`
                    ).appendTo(this.$wrapper);
                    const bootstrapModules = [];
                    let html = '';
                    if (this.watchedSettings.layout === 'list') {
                        this.modules.forEach((m, index) => {
                            html += this.generateSALModuleHTML(m, index);
                            bootstrapModules.push(m.name);
                        });
                    } else {
                        bootstrapModules.push(this.activeModule.name);
                        html += this.generateSALModuleHTML(
                            this.activeModule,
                            0
                        );
                    }
                    this.$bootstrapElement.append(html);

                    bootstrapModules.forEach(name => {
                        const SALApplication = window.angular.module(name, [
                            'SALModule'
                        ]);
                        SALApplication.config([
                            '$compileProvider',
                            $compileProvider => {
                                $compileProvider.aHrefSanitizationWhitelist(
                                    /^\s*(https?|javascript):/
                                );
                            }
                        ]);
                        this.moduleStatus[name] = true;
                        this.$salModules = this.$wrapper.find(
                            `.${this.namespace('sal-module')}`
                        );
                        this.SALApplication.push(SALApplication);
                        this.setSALModuleValues(SALApplication);
                    });
                    window.angular.bootstrap(
                        window.angular.element(this.$bootstrapElement),
                        bootstrapModules
                    );
                    this.resize(this.$wrapper.width());
                    this.showLoading = false;
                });
            }
        },
        _scrollActiveModule(item) {
            this.$nextTick(() => {
                if (this.watchedSettings.layout === 'list') {
                    const activeModule = utils.find(
                        this.modules,
                        m => m.id === item.id
                    );
                    if (activeModule) {
                        const $activeModule = this.$wrapper.find(
                            `[mwc-id='${activeModule.mwcId}']`
                        );
                        if ($activeModule.length > 0) {
                            const currentScrollTop = this.$wrapper.scrollTop();
                            const scrollTop =
                                currentScrollTop +
                                $activeModule.offset().top -
                                this.$wrapper.offset().top;
                            this.$wrapper.scrollTop(scrollTop);
                        }
                    }
                }
            });
        },
        generateSALModuleHTML(m, index) {
            return `<section class="${this.namespace(
                'sal-module'
            )} ${this.namespace('sal-module-' + index)}" data-id="${m.id}"><${
                m.name
            } mwc-id="${m.mwcId}"></${m.name}></section>`;
        },
        getSALModuleDeps() {
            if (this.watchedSettings.layout === 'list') {
                const allDepSet = new Set();
                this.modules.forEach(m => {
                    m.deps.forEach(dep => {
                        allDepSet.add(dep);
                    });
                });
                return Array.from(allDepSet);
            } else {
                return this.activeModule.deps;
            }
        },
        getFullScriptSrc(path, src) {
            if (/http/.test(src)) {
                return src;
            }
            return `${path}sal-report-bundle/dist/${src}-bundle.min.js`;
        },
        _getStoreSettings() {
            const settings = {
                symbol: this.security
                    ? this.getSavedSymbol(this.security)
                    : this.watchedSettings.symbol
            };
            if (
                this.autoChangeModule &&
                this.watchedSettings.layout !== 'list'
            ) {
                const activeModule = utils.find(this.modules, m => m.active);
                settings.activeModule = activeModule.id;
            }
            return settings;
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        },
        quickPrint() {
            if (this.showLoading) {
                return null;
            }
            // when the sal component in the small screen, we need to change the layout in max screen.
            if (this.$wrapper.width() + 100 < this.documentWidth) {
                this.$wrapper.css({ width: this.documentWidth });
                this.resize(this.documentWidth);
            }
            this.showLoading = true;
            this.containerEle = this.$wrapper[0];
            let payload = this.generatePdfPayload();
            this.pdfCreatorService(payload);
        },
        generatePdfPayload() {
            const pdfTemplate = this.watchedSettings.pdfPrintConfig
                .hasPdfPageBreaks
                ? 'sal-generic'
                : 'sal-no-page-breaks';
            const settings = {
                contents: this.containerEle.outerHTML,
                orientation: this.watchedSettings.pdfPrintConfig.orientation,
                pdfMargins: this.watchedSettings.pdfPrintConfig.pdfMargins,
                pdfSizes: this.watchedSettings.pdfPrintConfig.pdfSizes,
                pdfFooter: this.watchedSettings.pdfPrintConfig.pdfFooterText,
                pdfHeader: this.watchedSettings.pdfPrintConfig.pdfHeaderText,
                template: pdfTemplate,
                hasPdfPageBreaks: this.watchedSettings.pdfPrintConfig
                    .hasPdfPageBreaks,
                useMorningstarFont: this.watchedSettings.pdfPrintConfig
                    .useMorningstarFont,
                pdfMarginStyle: this.watchedSettings.pdfPrintConfig
                    .pdfMarginStyle
            };
            const css = this.getStylesArray();
            const requestData = { settings, css };
            const payload = JSON.stringify(requestData);

            return payload;
        },
        getStylesArray() {
            const styles = document.getElementsByTagName('style');
            const css = [];
            for (let i = 0; i < styles.length; i++) {
                css.push({ text: styles[i].outerHTML });
            }
            const pdfOverrideStyle = this.watchedSettings.pdfPrintConfig
                .pdfOverrideStyle;
            if (pdfOverrideStyle) {
                css.push({ text: pdfOverrideStyle });
            }
            return css;
        },
        generateDownloadFile(rawBase64) {
            const type = 'application/pdf';
            const binary = atob(rawBase64.replace(/\s/g, ''));
            const binaryLength = binary.length;
            const buffer = new ArrayBuffer(binaryLength);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < binaryLength; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], { type });
            return blob;
        },
        invokeDownload(fileBlob) {
            const link = document.createElement('a');
            let downloadUrl = URL.createObjectURL(fileBlob);
            link.setAttribute('href', downloadUrl);
            link.setAttribute('target', '_blank');
            link.setAttribute('download', 'automated.pdf');
            const timeStamp = new Date();
            let fileName = '';
            if (this.investmentId) {
                fileName = `${
                    this.investmentId
                }_${timeStamp.toISOString()}.pdf`;
            } else {
                fileName = `PDF_${timeStamp.toISOString()}.pdf`;
            }

            if (link.download !== undefined) {
                // Set HTML5 download attribute. This will prevent file from opening if supported.
                link.download = fileName;
            }
            this.showLoading = false;
            // for IE11
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(fileBlob, fileName);
                return;
            }

            if (document.createEvent) {
                const e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return;
            }

            // appendUrlParam in core to replace this
            if (downloadUrl.indexOf('?') === -1) {
                downloadUrl += '?download';
            }
            window.open(downloadUrl, '_blank');
        },

        pdfCreatorService(payload) {
            if (!payload) {
                return false;
            }
            //when changed the layout with small screen, we should revert before width.
            if (this.$wrapper.attr('style')) {
                this.$wrapper.removeAttr('style');
                this.resize(this.$wrapper.width());
            }
            const { service } = this.getSALPaths({
                bundle: this.watchedSettings.salBundleHost,
                service: this.watchedSettings.salServiceHost
            });
            const { realTimeToken, oauth2Token } = this.watchedSettings;
            const url = `${service}/sal-service/v1/pdf/export?access_token=${oauth2Token}`;
            // const url = `http://10.86.16.244:3001/export?access_token=${oauth2Token}`;
            $.ajax({
                url,
                type: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-REALTIME-E': realTimeToken,
                    'X-SAL-ContentType':
                        this.watchedSettings.type === 'premium'
                            ? 'nNsGdN3REOnPMlKDShOYjlk6VYiEVLSdpfpXAm7o2Tk='
                            : 'e7FDDltrTy+tA2HnLovvGL0LFMwT+KkEptGju5wXVTU=',
                    'X-API-RequestId': utils.guid()
                },
                data: payload,
                success: res => {
                    const downloadFile = this.generateDownloadFile(res.file);
                    this.invokeDownload(downloadFile);
                },
                error: () => {
                    this.showLoading = false;
                }
            });
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-report';
.#{$namespace} {
    &-content {
        height: 100%;
    }

    &-wrapper {
        position: relative;
        overflow-y: auto;
        height: calc(100% - 45px);

        .sal-component-card,
        .sal-row {
            max-width: unset;
        }
        &__no-title {
            .sal-component-title {
                display: none;
            }
        }
        .sal-component-take {
            .sal-component-body {
                margin-bottom: 10px;
            }
        }
        .sal-component-quote {
            .sal-full-key-ratios {
                display: none;
            }
            // .sal-component-news-filings {
            //     .list-see-all {
            //         display: none;
            //     }
            // }
        }
        .sal-component-financials {
            .sal-component-footer {
                display: none;
            }
        }
        .sal-component-executive {
            .sal-component-transaction-history
                .sal-transaction-history__footer
                .sal-component-footer__note {
                height: auto;
            }
        }
    }

    &-wrapper__no-nav {
        height: 100%;
    }

    &-error {
        height: calc(100% - 74px);
    }

    &-sal-module {
        width: 100%;
        height: 100%;
        &-0 {
            .sal-component-header {
                border-top: none;
            }
        }
    }

    &.markets-ui-container__dark-gray {
        .markets-ui-container-body {
            background: $mds-background-color-white;
            color: #000;
        }
    }
}
.sal-component-ctn .sal-reader-modal {
    max-height: calc(100% - 80px);
    overflow: auto;
}

.sal-component-ctn .sal-reader-modal .reader-content .reader-article-detail * {
    max-width: 100%;
}
@import './style/sal-report/dark.scss';
</style>
