var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showLoading: _vm.showLoading,
        showPrint: _vm.watchedSettings.showPrint
      },
      on: { "print-click": _vm.quickPrint }
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.namespace("content"),
            _vm.errorMessage ? _vm.namespace("error") : ""
          ],
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.watchedSettings.showNav && !_vm.errorMessage
            ? _c("navigation", {
                attrs: {
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.modules,
                  labels: _vm.initedLabels
                },
                on: { change: _vm.changeActiveModule }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.errorMessage
            ? _c("section", {
                ref: "salModuleWrapper",
                class: _vm.wrapperClass
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }