<template>
    <nav :class="classes">
        <markets-ui-button
            :class="[namespace('__icon'), namespace('__left')]"
            variation="icon-only"
            icon="angle-left"
            :skin="skin"
            :text="labels.left"
            v-show="showLeftControls"
            @click="clickControls($event, 'left')"
        ></markets-ui-button>
        <div :class="namespace('__content')">
            <ul :class="namespace('__list')" ref="list" @scroll="scroll">
                <li
                    v-for="item in dataModel"
                    :key="item.id"
                    :class="namespace('__list-item')"
                    ref="links"
                >
                    <div
                        :class="[
                            namespace('__list-item-inner'),
                            item.active ? namespace('__list-item--active') : ''
                        ]"
                    >
                        <markets-ui-button
                            variation="flat"
                            :text="item.label"
                            :skin="skin"
                            size="medium"
                            @click="clickItem(item)"
                        ></markets-ui-button>
                    </div>
                </li>
            </ul>
        </div>
        <markets-ui-button
            v-show="showRightControls"
            variation="icon-only"
            :skin="skin"
            :class="[namespace('__icon'), namespace('__right')]"
            icon="angle-right"
            :text="labels.right"
            @click="clickControls($event, 'right')"
        ></markets-ui-button>
    </nav>
</template>

<script>
import $ from 'jquery';
import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export default {
    props: {
        skin: {
            type: String,
            default: ''
        },
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            scrollable: false,
            showLeftControls: false,
            showRightControls: false
        };
    },
    computed: {
        classes() {
            const array = [this.namespace()];
            if (this.skin) {
                array.push(
                    `${this.namespace()}--${utils.getSkinSuffix(this.skin)}`
                );
            }
            if (this.scrollable) {
                array.push(this.namespace('__scrollable'));
            }
            return array;
        }
    },
    created() {
        this.SCROLL_DELAY = 200;
        this.SCROLL_MARGIN = 10;
        this.SCROLL_AMOUNT = 250;
        this.namespace = utils.namespace('report-navigation');
    },
    mounted() {
        utils.resizeObserver.observe(this.$el, this.resize);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this.resize);
    },
    methods: {
        clickItem(item) {
            if (!item.active) {
                this.$emit('change', item);
            }
        },
        clickControls(e, direction) {
            const target = this.$refs.list;
            const currentPosition = target.scrollLeft;
            if (direction === 'right') {
                $(target).animate(
                    {
                        scrollLeft: currentPosition + this.SCROLL_AMOUNT
                    },
                    this.SCROLL_DELAY
                );
            } else {
                $(target).animate(
                    {
                        scrollLeft: currentPosition - this.SCROLL_AMOUNT
                    },
                    this.SCROLL_DELAY
                );
            }
        },
        scroll(e) {
            this.resetControls(e.currentTarget);
        },
        resize() {
            const target = this.$refs.list;
            this.scrollable = false;
            if (target) {
                const maxScroll = target.scrollWidth - target.clientWidth;
                if (maxScroll > 0) {
                    this.scrollable = true;
                    this.resetControls(target);
                } else {
                    this.scrollable = false;
                }
            } else {
                this.scrollable = false;
            }
        },
        resetControls(target) {
            this.$nextTick(() => {
                const currentPosition = target.scrollLeft;
                const maxScroll = target.scrollWidth - target.clientWidth;
                this.showLeftControls = currentPosition > this.SCROLL_MARGIN;
                this.showRightControls =
                    currentPosition < maxScroll - this.SCROLL_MARGIN;
            });
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-report-navigation';
.#{$namespace} {
    ul,
    li {
        margin: 0;
        padding: 0;
    }
    li {
        list-style-type: none;
    }
    height: 44px;
    border-bottom: solid 1px $mds-color-neutral-80;
    position: relative;

    &__icon.markets-ui-button {
        display: none;
        background: #fff;
        svg {
            height: 15px;
            width: 15px;
        }
    }

    &__content {
        height: 100%;
    }

    &__list {
        align-items: stretch;
        display: flex;
        height: 100%;
        justify-content: flex-start;
        overflow-x: hidden;
        &-item {
            display: flex;
            flex-shrink: 0;
            &:first-child {
                padding-left: 4px;
            }
            &:not(:last-child) {
                padding-right: 24px;
            }
            &-inner {
                align-items: center;
                display: flex;
            }

            &--active {
                box-shadow: inset 0 -4px 0 $mds-text-color-interactive-primary;
            }
        }
    }
    &__button {
        white-space: nowrap;
        &--flat.#{$namespace}__button:focus {
            box-shadow: none;
        }
    }
    &__scrollable {
        .#{$namespace}__icon {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: absolute;
            top: 0;
            height: 100%;
            width: 44px;
            z-index: 10;
            cursor: pointer;
            opacity: 1;
            visibility: visible;
        }
        .#{$namespace}__left {
            left: 0;
        }
        .#{$namespace}__right {
            right: 0;
        }
    }

    &--dark-gray {
        background: $mds-background-color-dark-gray;
        color: $mds-text-color-primary-on-dark;

        .#{$namespace}__icon.markets-ui-button {
            background: $mds-background-color-dark-gray;
            svg {
                fill: $mds-text-color-primary-on-dark;
            }
        }
        .#{$namespace}__list {
            &-item {
                &--active {
                    box-shadow: inset 0 -4px 0 0 $mds-text-color-interactive-primary-on-dark;
                }
            }
        }
    }
}
</style>
