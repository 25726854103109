export const MODULES = {
    equity: {
        quote: {
            name: 'sal-components-quote'
        },
        analysis: {
            name: 'sal-components-take',
            licenses: ['free', 'premium']
        },
        priceFairvalue: {
            name: 'sal-components-price-fairvalue',
            licenses: ['free', 'premium']
        },
        trailingReturns: {
            name: 'sal-components-trailing-returns'
        },
        financials: {
            name: 'sal-components-eqsv-financials'
        },
        valuation: {
            name: 'sal-components-valuation'
        },
        performance: {
            name: 'sal-components-oper-perf',
            labelKey: 'operatingPerformance'
        },
        dividends: {
            name: 'sal-components-dividends'
        },
        ownership: {
            name: 'sal-components-ownership'
        },
        executive: {
            name: 'sal-components-executive'
        },
        profile: {
            name: 'sal-components-company-profile'
        }
    },
    managedInvestment: {
        quote: {
            name: 'sal-components-mip-quote'
        },
        analysis: {
            name: 'sal-components-mip-take',
            licenses: ['free', 'premium']
        },
        performance: {
            name: 'sal-components-performance'
        },
        risk: {
            name: 'sal-components-mip-risk'
        },
        price: {
            name: 'sal-components-pillar-cards-price'
        },
        portfolio: {
            name: 'sal-components-pillar-cards-process'
        },
        people: {
            name: 'sal-components-pillar-cards-people'
        },
        parent: {
            name: 'sal-components-pillar-cards-parent'
        }
    },
    index: {
        quote: {
            name: 'sal-components-mip-quote'
        },
        description: {
            name: 'sal-components-index-description'
        },
        performance: {
            name: 'sal-components-performance'
        },
        risk: {
            name: 'sal-components-mip-risk'
        },
        portfolio: {
            name: 'sal-components-pillar-cards-process'
        }
    }
};
