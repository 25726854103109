var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { class: _vm.classes },
    [
      _c("markets-ui-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLeftControls,
            expression: "showLeftControls"
          }
        ],
        class: [_vm.namespace("__icon"), _vm.namespace("__left")],
        attrs: {
          variation: "icon-only",
          icon: "angle-left",
          skin: _vm.skin,
          text: _vm.labels.left
        },
        on: {
          click: function($event) {
            return _vm.clickControls($event, "left")
          }
        }
      }),
      _vm._v(" "),
      _c("div", { class: _vm.namespace("__content") }, [
        _c(
          "ul",
          {
            ref: "list",
            class: _vm.namespace("__list"),
            on: { scroll: _vm.scroll }
          },
          _vm._l(_vm.dataModel, function(item) {
            return _c(
              "li",
              {
                key: item.id,
                ref: "links",
                refInFor: true,
                class: _vm.namespace("__list-item")
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      _vm.namespace("__list-item-inner"),
                      item.active ? _vm.namespace("__list-item--active") : ""
                    ]
                  },
                  [
                    _c("markets-ui-button", {
                      attrs: {
                        variation: "flat",
                        text: item.label,
                        skin: _vm.skin,
                        size: "medium"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clickItem(item)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("markets-ui-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRightControls,
            expression: "showRightControls"
          }
        ],
        class: [_vm.namespace("__icon"), _vm.namespace("__right")],
        attrs: {
          variation: "icon-only",
          skin: _vm.skin,
          icon: "angle-right",
          text: _vm.labels.right
        },
        on: {
          click: function($event) {
            return _vm.clickControls($event, "right")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }