(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"), require("mwc-markets-core"));
	else if(typeof define === 'function' && define.amd)
		define(["jquery", "mwc-markets-core"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsReport"] = factory(require("jquery"), require("mwc-markets-core"));
	else
		root["mwcMarketsReport"] = factory(root["jQuery"], root["mwcMarketsCore"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__25__) {
return 